import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Formik, Form } from "formik";
import {
  createAdminSupport,
  updateAdminSupport,
} from "../../actions/adminSupportActions";
import { getAllUsers } from "../../actions/adminDashActions";
import { getAccounts } from "../../actions/account/accountAdminActions";
import {
  formatDateTimeUtc,
  formatDateTimeToUtc,
} from "../../actions/momentActions";
import { validate, defaultValues } from "./validate";
import Grid from "@mui/material/Grid";
import InputBox from "../../components/InputBox";
import InputDateBox from "../../components/InputDateBox";
import SelectBox from "../../components/SelectBox";
import AdminAccountSearch from "../../components/AdminAccountSearch";
import Error from "../Error";
import Success from "../Success";

import { LABELS as _LABELS } from "../../language";


const LABELS = _LABELS.supportForm;

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export class AddResellerForm extends React.Component {
  state = {
    accounts: [],
    drivers: [],
    supUsers: [],
  };

  componentDidMount() {
    this.getSupportUsers();
    const selected = this.props.selected;
    if (selected && selected.accountId) {
      this.getDrivers(selected.accountId);
    }
  }

  getAccounts = async () => {
    const accounts = await this.props.getAccounts({});
    this.setState({ accounts: accounts || [] });
  };

  getDrivers = async (accountId) => {
    const drivers = await this.props.getAllUsers({
      accountId,
      skip: 0,
      limit: 500,
    });
    this.setState({ drivers: drivers || [] });
  };

  getSupportUsers = async () => {
    const supUsers = await this.props.getAllUsers({
      skip: 0,
      limit: 500,
      searchSupport: "ON",
    });
    this.setState({ supUsers: supUsers || [] });
  };

  handleSubmit = async (values) => {
    this.setState({ errorMessage: "", successMessage: "", loading: true });

    let response;

    values.callTm = this.props.formatDateTimeToUtc(values.callTm);
    if (values._id) {
      response = await this.props.updateAdminSupport(values);
    } else {
      response = await this.props.createAdminSupport(values);
    }

    if (response && response._id) {
      this.setState({
        successMessage: `${LABELS.successMessage}`,
        loading: false,
      });
      setTimeout(() => {
        this.props.handleClose();
      }, 1000);
    } else if (response && response.message) {
      this.setState({ errorMessage: response.message, loading: false });
    } else {
      if (typeof response !== String) {
        response = `${LABELS.response}`;
      }
      this.setState({ errorMessage: response, loading: false });
    }
  };

  render() {
    const { classes, constants } = this.props;
    const accounts = this.state.accounts || [];
    const drivers = this.state.drivers || [];
    const supUsers = this.state.supUsers || [];
    // const user = this.props.user || {};
    const selected = this.props.selected || {};
    const isSmallScreen = window.innerWidth < 400;
    const { errorMessage, successMessage, loading } = this.state;

    let initialValues = {
      ...defaultValues,
      // assignedTo,
      ...selected,
    };

    let selectedAcc;
    if (selected && selected.accountName && selected.accountId) {
      selectedAcc = { id: selected.accountId, name: selected.accountName };
    }
    if (selected && selected.callTm && selected.callTm) {
      initialValues.callTm = this.props.formatDateTimeUtc(selected.callTm);
    }

    return (
      <Dialog
        fullScreen={isSmallScreen}
        fullWidth={true}
        maxWidth={"xl"}
        className={classes.wrapper}
        open={this.props.isAdd}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography>{`${LABELS.addsupportcase}`}</Typography>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validate={validate}
            onSubmit={this.handleSubmit}
            enableReinitialize={true}
          >
            {({ values, handleSubmit, setFieldValue }) => {
              return (
                <Form style={{ padding: "20px 10px" }}>
                  <Grid container spacing={3}>
                    <AdminAccountSearch
                      md={3}
                      selValue={"_id"}
                      selLabel={"name"}
                      name="accountId"
                      label={`${LABELS.account}`}
                      items={accounts}
                      selected={selectedAcc}
                      onChange={(e) => {
                        if (e && e._id) {
                          this.getDrivers(e._id);
                          setFieldValue("accountId", e._id);
                          setFieldValue("accountName", e.name);
                        }
                      }}
                    />
                    <SelectBox
                      md={3}
                      selValue={"id"}
                      selLabel={"firstName"}
                      selLabelTwo={"lastName"}
                      selLabelThree={"status"}
                      name="driverId"
                      label={`${LABELS.driver}`}
                      items={drivers}
                      onChange={(e) => {
                        const _dId = e.target.value;
                        const _driver = drivers.find(
                          (item) => item.id === _dId
                        );
                        const _caller = {
                          id: _driver.id,
                          name: `${_driver.firstName} ${_driver.lastName}`,
                          email: _driver.email,
                          phone: _driver.mobile,
                        };
                        setFieldValue("caller", _caller);
                      }}
                    />
                    <SelectBox
                      md={2}
                      name="category"
                      label={`${LABELS.category}`}
                      items={constants.SUPPORT_CATEGORY}
                    />
                    <SelectBox
                      md={2}
                      name="callType"
                      label={`${LABELS.type}`}
                      items={constants.SUPPORT_TYPES}
                    />
                    <SelectBox
                      md={2}
                      name="product"
                      label={`${LABELS.product}`}
                      items={constants.SUPPORT_PRODUCT}
                    />
                    <SelectBox
                      md={1}
                      name="priority"
                      label={`${LABELS.priority}`}
                      items={constants.SUPPORT_CALL_PRORIRY}
                    />
                    <SelectBox
                      md={2}
                      name="callStatus"
                      label={`${LABELS.status}`}
                      items={constants.SUPPORT_CALL_STATUS}
                    />
                    <InputDateBox
                      type={"datetime-local"}
                      md={3}
                      name="callTm"
                      label={`${LABELS.time}`}
                    />
                    <SelectBox
                      md={2}
                      selValue={"id"}
                      selLabel={"firstName"}
                      selLabelTwo={"lastName"}
                      selLabelThree={"status"}
                      name="assignedTo.id"
                      label={`${LABELS.assignedto}`}
                      items={supUsers}
                      onChange={(e) => {
                        const _uId = e.target.value;
                        const _sel =
                          supUsers.find((item) => item.id === _uId) || {};

                        setFieldValue(
                          "assignedTo.name",
                          `${_sel.firstName} ${_sel.lastName}`
                        );
                        setFieldValue("assignedTo.email", _sel.email);
                        setFieldValue("assignedTo.id", _uId);
                      }}
                    />
                    <InputBox
                      md={2}
                      name="assignedTo.name"
                      label={`${LABELS.assignedto}`}
                    />
                    <InputBox
                      md={2}
                      name="assignedTo.email"
                      label={`${LABELS.assignee_email}`}
                    />
                    <InputBox md={6} name="title" label={`${LABELS.issue}`} />
                    <InputBox md={6} name="detail" label={`${LABELS.detail}`} />
                    <InputBox
                      md={12}
                      name="solution"
                      label={`${LABELS.Solution}`}
                    />

                    <InputBox
                      md={4}
                      name="caller.name"
                      label={`${LABELS.caller_name}`}
                    />
                    <InputBox
                      md={4}
                      name="caller.email"
                      label={`${LABELS.caller_email}`}
                    />
                    <InputBox
                      md={4}
                      name="caller.phone"
                      label={`${LABELS.caller_phone}`}
                    />

                    <SelectBox
                      md={values.cb === "YES" ? 2 : 12}
                      name="cb"
                      label={`${LABELS.callback}`}
                      items={constants.SUPPORT_CALL_BACK}
                    />
                    {values.cb === "YES" && (
                      <>
                        <InputDateBox
                          md={3}
                          name="cbTm"
                          label={`${LABELS.callback_time}`}
                          type={"datetime-local"}
                        />
                        <InputBox
                          md={3}
                          name="cbInfo.name"
                          label={`${LABELS.name}`}
                        />
                        <InputBox
                          md={2}
                          name="cbInfo.email"
                          label={`${LABELS.email}`}
                        />
                        <InputBox
                          md={2}
                          name="cbInfo.phone"
                          label={`${LABELS.phone}`}
                        />
                      </>
                    )}
                  </Grid>
                  {successMessage && <Success message={successMessage} />}
                  {errorMessage && <Error message={errorMessage} />}
                  <DialogActions className={classes.DialogActions}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={loading}
                    >
                      {`${loading ? LABELS.saving : LABELS.save}`}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={this.props.handleClose}
                    >
                      {`${LABELS.closed}`}
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: _get(state, "authData.profile.user", {}),
    constants: _get(state, "appData.constants", {}),
    errorMessage: _get(state, "equipmentData.error", ""),
    loading: _get(state, "accountData.accountCreateBusy", false),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAccounts,
      getAllUsers,
      createAdminSupport,
      updateAdminSupport,
      formatDateTimeUtc,
      formatDateTimeToUtc,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AddResellerForm))
);
