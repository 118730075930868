import _get from "lodash/get";
import React, { useState } from "react";
import { Field } from "formik";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const InputField = ({
    field,
    type,
    disabled,
    form: { touched, errors, values, handleChange, handleBlur },
    onChange,
    onBlur,
    autoComplete = "on",
    helpText,
    ...props
}) => {
    const [inputType, setInputType] = useState(type);
    const [isPwdVisible, setIsPwdVisible] = useState(false);
    let error = _get(errors, `${field.name}`);
    let touch = _get(touched, `${field.name}`);
    let value = _get(values, `${field.name}`);

    const showPwd = type === "password";
    // const tName = field.name.replace(/\./g, "-");

    const handleClickShowPassword = () => {
        if (inputType === "password") {
            setInputType("text");
        } else {
            setInputType("password");
        }
        setIsPwdVisible(!isPwdVisible)
    };

    const handleMouseDownPassword = event => {
        event.preventDefault();
    };
    // console.log("value",value)
    return (
        <TextField
            size="small"
            variant="standard"
            type={inputType}
            InputLabelProps={{ shrink: field.value }} 
            name={field.name}
            label={props.label}
            placeholder={props.placeholder}
            value={value}
            autoComplete={autoComplete}
            onChange={e => {
                onChange(e);
                handleChange(e);
            }}
            onBlur={e => {
                onBlur(e);
                handleBlur(e);
            }}
            helperText={error ? error : helpText}
            error={touch && Boolean(error)}
            fullWidth
            InputProps={{
                readOnly: disabled,
                endAdornment: showPwd ? (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {isPwdVisible ? (
                                <Visibility />
                            ) : (
                                <VisibilityOff />
                            )}
                        </IconButton>
                    </InputAdornment>
                ) : null
            }}
        />
    );
};

const InputBox = ({
    name,
    label,
    type = "text",
    placeholder,
    // autoComplete = "on",
    disabled = false,
    onBlur = () => { },
    onChange = () => { },
    md = 4,
    xs = 12,
    value,
    helpText = ""
}) => {
    return (
        <Grid item md={md} xs={xs}>
            <Field
                type={type}
                name={name}
                // autoComplete={autoComplete}
                label={label || name}
                disabled={disabled}
                component={InputField}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                helpText={helpText}
            />
        </Grid>
    );
};

export default InputBox;
