import _get from "lodash/get";
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import BlockIcon from "@mui/icons-material/Block";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import LongMenu from "../../components/Menu";
import ReassignDriving from "../ReassignDriving";
import ConfirmActionModal from "../ConfirmActionModal";
import { RECORD_ORIGIN } from "../../constantsStatus";
import DutyStatus from "../../components/DutyStatus";
import ConfirmDialog from "../../components/ConfirmDialog";
import {  validateOdometer, validateLocation } from "./helpers";
// import { validateEngHr} from "./helpers";
import {LABELS as _LABELS} from "../../language";

const LABELS=_LABELS.dutyPage;


function pF(value) {
  return parseFloat(value) ? parseFloat(value) : 0;
}

const styles = (theme) => ({
  root: {
    width: "98%",
    marginRight: "auto",
    marginLeft: "auto",
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  tableHeaderCell: {
    textAlign: "left",
    padding: 10,
    minWidth: 55,
  },
  tableRowCell: {
    fontSize: 12,
    padding: 10,
  },
  cycleReset: {
    // textAlign: "left",
    // marginLeft: 55,
  },
  textLeft: {
    textAlign: "left",
  },
  textCenter: {
    textAlign: "center",
  },
  textInactive: {
    textDecoration: "line-through",
  },
  upperCase: {
    textTransform: "uppercase",
  },
  errorText: {
    color: "red",
  },
});

class ReduxTableRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
  }

  handleMenuClick = async (e, name, value, index, prevLog, prevLog2) => {
    this.props.setSelectedLog(value);

    if (name === "Edit") {
      this.props.handleLogEdit(value, name, "edit", index, prevLog, prevLog2);
    } else if (name === "Reassign") {
      this.props.handleReassignDrivingModal(e, true);
    } else if (name === "Edit Admin") {
      this.props.handleLogEdit(
        value,
        name,
        "editAdmin",
        index,
        prevLog,
        prevLog2
      );
    } else if (name === "Duplicate Admin") {
      this.props.handleLogEdit(
        value,
        name,
        "duplicateAdmin",
        index,
        prevLog,
        prevLog2
      );
    } else if (name === "Deactivate Admin") {
      this.props.deleteAdminLogs(value);
    } else if (name === "Delete") {
      this.props.deleteCalcus(value);
    }
  };

  handleCheckChange = (event) => {
    const _checked = event.target.checked;
    this.setState({ checked: _checked });
  };

  render() {
    const {
      classes,
      // cycleStart,
      item = {},
      isAllLog = false,
      isCoDriver = false,
      isDebug = false,
      role,
      adminKey,
      timezone,
      prevLog,
      prevLog2,
      isAdminDs,
      isAdminDsMul,
      // isUnity,
      isFmcsaSend,
      isViewOnly,
      isSmartelds,
    } = this.props;

    // const isEhValid = validateEngHr(item, prevLog, prevLog2);
    const isOdoValid = validateOdometer(item, prevLog, prevLog2);
    const isLocValid = !validateLocation(item, prevLog, prevLog2);
    // const checked = this.state.checked;

    const eventTypeItems = isAllLog
      ? [
          "CHANGE_DUTY_STATUS",
          "INTERMEDIATE_LOG",
          "YARD_MOVES",
          "PERSONAL_USE",
          "CERTIFICATION",
          "LOGIN_LOGOUT",
          "POWERUP_SHUTDOWN",
          "MALFUNCTION_DIAGNOSTIC",
        ]
      : [
          "CHANGE_DUTY_STATUS",
          "YARD_MOVES",
          "PERSONAL_USE",
          // "CERTIFICATION",
          // "POWERUP_SHUTDOWN"
        ];

    const recordStatusItems = isAllLog
      ? [
          "ACTIVE",
          "INACTIVE_CHANGED",
          "INACTIVE_DEACTIVATE",
          "INACTIVE_CHANGE_REQUEST",
          "INACTIVE_CHANGE_REJECTED",
        ]
      : ["ACTIVE"];
    // : ["ACTIVE", "INACTIVE_CHANGE_REQUEST", "INACTIVE_CHANGE_REJECTED"];

    const isCycleStart = item.cycleStartTimestamp;
    const shiftReset = item.shiftReset;
    const tableClass = isCycleStart
      ? ""
      : shiftReset
      ? ""
      : item.recordStatus === "INACTIVE_CHANGE_REQUEST"
      ? ""
      : item.recordStatus === "ACTIVE"
      ? ""
      : `${classes.textInactive}`;

    const isAllowed =
      recordStatusItems.includes(item.recordStatus) &&
      eventTypeItems.includes(item.eventType);

    if (isCycleStart || shiftReset) {
    } else if (!isAllowed || item.isLast) {
      return null;
    }

    let isBlocked = item.locked;
    let isMenu = item.recordStatus !== "INACTIVE_CHANGED";
    let blockText = "Waiting for driver to accept or reject.";
    let menuItems = [];
    let hide = false;

    const noChangeItems = [
      "LOGIN_LOGOUT",
      "POWERUP_SHUTDOWN",
      "CERTIFICATION",
      "INTERMEDIATE_LOG",
      "MALFUNCTION_DIAGNOSTIC",
    ];
    const noChangeStatusItems = [
      "INACTIVE_CHANGED",
      "INACTIVE_DEACTIVATE",
      "INACTIVE_CHANGE_REJECTED",
    ];
    const reAssignEventCode = ["DRIVING", "ONDUTY_ND"];
    const adminRoles = _get(this, "props.adminScope", []);
    const isAdmin = adminRoles && adminRoles.includes("ADMIN_DS_ALL");
    const isCertify = item.eventType === "CERTIFICATION";

    // console.log("item.eventType ", item.eventCode);
    if (
      noChangeItems.includes(item.eventType) ||
      noChangeStatusItems.includes(item.recordStatus) ||
      isCycleStart ||
      shiftReset
    ) {
      // menuItems = ["Delete"];
    } else if (
      item.eventType === "CHANGE_DUTY_STATUS" &&
      item.recordOrigin === "AUTOMATICALLY" &&
      reAssignEventCode.includes(item.eventCode)
    ) {
      if (isCoDriver) {
        menuItems = ["Reassign"];
      } else {
        menuItems = [];
      }
      if (isAdmin) {
        menuItems.push("Edit");
      }
    } else if (
      item.eventType === "CHANGE_DUTY_STATUS" &&
      reAssignEventCode.includes(item.eventCode)
    ) {
      if (isCoDriver) {
        menuItems = ["Reassign", "Edit"];
      } else {
        menuItems = ["Edit"];
      }
    } else {
      menuItems = ["Edit"];
    }
    // if (
    //     isDebug &&
    //     item.eventType === "CHANGE_DUTY_STATUS" &&
    //     (item.eventCode === "DRIVING" || item.eventCode === "ONDUTY_ND")
    // ) {
    //     if (isCoDriver) {
    //         if (isAdmin) {
    //             menuItems = ["Reassign", "Edit"];
    //         } else {
    //             menuItems = ["Reassign"];
    //         }
    //     } else {
    //         if (isAdmin) {
    //             menuItems = ["Edit"];
    //         } else {
    //             menuItems = [];
    //         }
    //     }
    // }
    const evtTime = item.eventTimeOrg || item.eventTime;

    if (
      adminKey &&
      isCoDriver &&
      item.eventTime ===
        (this.props.startOfDay(evtTime) &&
          this.props.startOfDay(evtTime).toISOString())
    ) {
      menuItems = ["Edit"];
    } else if (
      item.eventTime ===
      (this.props.startOfDay(evtTime) &&
        this.props.startOfDay(evtTime).toISOString())
    ) {
      menuItems = [];
    }
    if (isCertify) {
      menuItems = [];
    }

    if (item && item.id) {
      if (isAdminDs) {
        if (menuItems.filter((e) => e === "Edit").length === 0) {
          menuItems.push("Edit");
        }
        isBlocked = false;
        hide = false;
        isMenu = true;
        menuItems.push("Edit Admin");
        menuItems.push("Deactivate Admin");
        menuItems.push("Duplicate Admin");
      } else {
        if (
          (item.eventSubType === "clearPc" ||
            item.eventSubType === "clearYm" ||
            item.eventSubType === "EXP:AGIS" ||
            item.eventSubType === "EXP:AGIE") &&
          adminRoles &&
          adminRoles.includes("ADMIN_ALL")
        ) {
          // isBlocked = true
          // blockText = "Personal and Yard Move cannot be edited";
          hide = true;
        }
      }
    }

    if (item.recordStatus === "INACTIVE_CHANGE_REJECTED") {
      isMenu = false;
    }

    if ((isCycleStart || shiftReset) && isAdmin) {
      menuItems.push("Delete");
    }
    // if (isPastDate(cycleStart, item.eventTime)) {
    //     menuItems = [];
    //     // isBlocked = true;
    //     blockText = "Too old event to be edited";
    // }

    const cycleTime = this.props.formatTimeSec(
      item.cycleStartTimestamp,
      timezone
    );
    const cycleDate = this.props.formatRawDate(
      item.cycleStartTimestamp,
      timezone
    );

    const shiftTime = adminKey
      ? this.props.formatTimeSec(item.shiftReset, timezone)
      : this.props.formatTime(item.shiftReset, timezone);
    const shiftDate = this.props.formatRawDate(item.shiftReset, timezone);

    const eventTime = adminKey
      ? this.props.formatTimeSec(evtTime, timezone)
      : this.props.formatTime(evtTime, timezone);
    const eventDate = this.props.formatRawDate(evtTime, timezone);
    const vehicle = item.vehicle || {}
    return (
      <TableRow hover className={`${classes.table} ${tableClass}`}>
        {isAdminDsMul ? (
          <TableCell className={`${classes.tableRowCell}`}>
            <Checkbox
              checked={item.isSelected || false}
              onChange={(ev) => this.props.handleCheckChange(ev, item)}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </TableCell>
        ) : null}
        <TableCell className={`${classes.tableRowCell}`}>
          {isCycleStart ? (
            <div>
              <strong>{cycleTime}</strong>
              <div>{cycleDate}</div>
            </div>
          ) : shiftReset ? (
            <div>
              <strong>{shiftTime}</strong>
              <div>{shiftDate}</div>
            </div>
          ) : (
            <div>
              <strong>{eventTime}</strong>
              <div>{eventDate}</div>
            </div>
          )}
        </TableCell>
        <TableCell className={`${classes.tableRowCell}`}>
          {isCycleStart || shiftReset ? (
            <div className={`${classes.cycleReset}`}>USED</div>
          ) : (
            <DutyStatus
              status={item}
              tableClass={tableClass}
              eventType={item.eventType}
              eventCode={item.eventCode}
              annotation={item.annotation}
              eventSubType={item.eventSubType}
            />
          )}
          {isAllLog && <div style={{ fontSize: 10 }}>{item.recordStatus}</div>}
          {/*={item.recordStatus}={item.eventType} = {item.eventCode} ={" "}
                    {item.eventSubType}*/}
          {/*(isYard || isPersonal) && (
                        <small className={classes.upperCase}>
                            {item.eventType}
                        </small>
                    )*/}
          {/*<small>{item.eventType}</small>*/}
        </TableCell>
        <TableCell className={`${classes.tableRowCell}`}>
          {isCycleStart ? (
            <div>{"34 Hour Cycle Reset"}</div>
          ) : shiftReset ? (
            <div>{"Shift Reset"}</div>
          ) : isCertify ? (
            ""
          ) : (
            <>
              {adminKey ? (
                <div className={isLocValid ? "" : classes.errorText}>
                  {item.location}
                </div>
              ) : (
                <div>{item.location}</div>
              )}
            </>
          )}
        </TableCell>

        <TableCell className={`${classes.tableRowCell}`}>
          {item.odometer && (
            <div className={isOdoValid ? "" : classes.errorText}>
              {isCertify ? "" : pF(item.odometer).toFixed()}
            </div>
          )}
        </TableCell>
        {isAdminDs && (
          <TableCell className={`${classes.tableRowCell}`}>
            {isCertify ? "" : item.engineHours}
          </TableCell>
        )}
        <TableCell className={`${classes.tableRowCell}`}>
          {isCertify ? "" : RECORD_ORIGIN[item.recordOrigin]}
        </TableCell>
        <TableCell className={`${classes.tableRowCell}`}>
          {isCertify ? "" : item.annotation ? <div>{item.annotation}</div> : ""}
        </TableCell>

        <TableCell className={`${classes.tableRowCell}`}>
          {isDebug && isAllLog && (
            <>
              {item.vehicle && item.vehicle.name ? (
                <div>{`Vehicle: ${vehicle.name}`}</div>
              ) : null}
              {item.sequenceId ? (
                <div>{isCertify ? "" : `Sq Id: ${item.sequenceId}`}</div>
              ) : null}
              {item.shippingDoc ? (
                <div>{`Shipping: ${item.shippingDoc}`}</div>
              ) : null}
              {item.trailer ? <div>{`Trailer: ${item.trailer}`}</div> : null}
              {item.coordinates ? (
                <div>{`Coords: ${item.coordinates}`}</div>
              ) : null}
              {isSmartelds && item.accumulatedMiles ? (
                <div>{`Acc Mi: ${item.accumulatedMiles}`}</div>
              ) : null}
              {isSmartelds && item.accumulatedHours ? (
                <div>{`Acc Hr: ${item.accumulatedHours}`}</div>
              ) : null}
            </>
          )}
        </TableCell>

        {!isViewOnly && (
          <TableCell>
            {isBlocked && role !== "DRIVER" && (
              <Tooltip title={blockText}>
                <BlockIcon />
              </Tooltip>
            )}
            {!isFmcsaSend &&
            !isBlocked &&
            menuItems.length &&
            isMenu &&
            role !== "DRIVER" &&
            !hide ? (
              <LongMenu
                labels={menuItems}
                prevLog={prevLog}
                prevLog2={prevLog2}
                selectedItem={item}
                handleMenuClick={this.handleMenuClick}
                index={this.props.index}
              />
            ) : null}
          </TableCell>
        )}
      </TableRow>
    );
  }
}

export class CurrentStatusDetailTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // updateSelectedLogs
      allChecked: false,
      driverLogs: props.driverLogs,
      isConfirmCalcusOpen: false,
    };
  }

  componentDidMount() {}

  handleReassignDrivingModal = (e, rDOpen) => {
    this.setState({ rDOpen });
  };

  onDelete = async () => {
    this.setState({ deleting: true });
    const selected = this.state.selected;

    await this.props.deleteAdminLogs(selected);
    await this.props.searchLogStatus();

    this.setState({
      isConfirmOpen: false,
      deleting: false,
      selected: null,
    });
  };

  onCalcusDelete = async () => {
    this.setState({ deleting: true });
    const selected = this.state.selected;

    await this.props.deleteAdminCalcus(selected);
    await this.props.searchLogStatus();

    this.setState({
      isConfirmCalcusOpen: false,
      deleting: false,
      selected: null,
    });
  };

  isCoDriver = () => {
    const dailyDiary = this.props.dailyDiary || [];

    let isExist = false;

    for (let i = 0; i < dailyDiary.length; i++) {
      const coD = dailyDiary[i].coDriver || [];

      if (coD && Array.isArray(coD) && coD.length > 0) {
        isExist = true;
        break;
      }
    }

    return isExist;
  };

  handleCheckAll = (event) => {
    let _driverLogs = [];

    if (event.target.checked) {
      _driverLogs = this.state.driverLogs.map((item) => {
        item.isSelected = true;
        return item;
      });
    } else {
      _driverLogs = this.state.driverLogs.map((item) => {
        item.isSelected = false;
        return item;
      });
    }
    this.setState({
      allChecked: event.target.checked,
      driverLogs: _driverLogs,
    });
    this.props.updateSelectedLogs(_driverLogs);
  };

  handleCheckChange = (event, selected) => {
    let _driverLogs = this.state.driverLogs.map((item) => {
      if (selected.id === item.id) {
        item.isSelected = event.target.checked;
      }
      return item;
    });

    this.setState({ driverLogs: _driverLogs });
    this.props.updateSelectedLogs(_driverLogs);
  };

  render() {
    const {
      classes,
      dailyDiary,
      cycleStart,
      isDebug,
      adminKey,
      isAdminDs,
      isAdminDsMul,
      isAllLog,
      isUnity,
      isFmcsaSend,
      isViewOnly,
    } = this.props;
    const driverLogs = this.state.driverLogs;
    const allChecked = this.state.allChecked;
    const isConfirmApprovalOpen = this.state.isConfirmApprovalOpen;
    const {
      rDOpen,
      isConfirmOpen,
      isConfirmCalcusOpen,
      selected,
      deleting,
    } = this.state;
    const isCoDriver = this.isCoDriver();
    const role = _get(this, "props.profile[0]", "");
    const timezone = _get(this, "props.dailyDiary[0].timezone");

    return (
      <div>
        {rDOpen && (
          <ReassignDriving
            open={rDOpen}
            dailyDiary={dailyDiary}
            editType={"REASSIGN"}
            handleClose={() => {
              this.setState({ rDOpen: false });
              this.props.searchLogs(this.props.selectedDate);
            }}
          />
        )}
        {isConfirmOpen && (
          <ConfirmActionModal
            open={isConfirmOpen}
            selected={selected}
            loading={deleting}
            handleClose={() =>
              this.setState({
                isConfirmOpen: false,
                deleting: false,
                selected: null,
              })
            }
            handleConfirm={() => {
              this.setState({
                isConfirmApprovalOpen: true,
              });
            }}
            label={"Deactivate"}
            message={
              "Are you sure, you want to deactivate selected duty status"
            }
            driverConfirm={true}
            btnLabel={"DeActivate"}
          />
        )}
        {isConfirmApprovalOpen && (
          <ConfirmDialog
            open={isConfirmApprovalOpen}
            label={"Driver action required"}
            agreeLabel={"Accept"}
            disAgreeLabel={"Reject"}
            text={"Please confirm with driver to accept or reject the request."}
            onClose={(isTrue) => {
              if (isTrue) {
                this.onDelete();
              }
              this.setState({
                isConfirmApprovalOpen: false,
                isConfirmOpen: false,
                deleting: false,
                selected: null,
              });
            }}
          />
        )}

        {isConfirmCalcusOpen && (
          <ConfirmActionModal
            open={isConfirmCalcusOpen}
            selected={selected}
            loading={deleting}
            handleClose={() =>
              this.setState({
                isConfirmCalcusOpen: false,
                deleting: false,
                selected: null,
              })
            }
            handleConfirm={this.onCalcusDelete}
            label={"Delete shift or cycle"}
            message={"Are you sure, you want to delete"}
          />
        )}
        <Paper className={classes.root}>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                {isAdminDsMul ? (
                  <TableCell className={classes.tableHeaderCell}>
                    <Checkbox
                      checked={allChecked}
                      onChange={this.handleCheckAll}
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </TableCell>
                ) : null}
                <TableCell className={classes.tableHeaderCell}>{LABELS.time}</TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  {LABELS.status}
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  {LABELS.Location}
                </TableCell>
                {/*<TableCell className={classes.tableHeaderCell}>
                                    Violation
                                </TableCell>*/}
                {/*<TableCell className={classes.tableHeaderCell}>
                                    Engine Hours
                                </TableCell>*/}
                <TableCell className={classes.tableHeaderCell}>
                  {`${LABELS.odometer}(Mi)`}
                </TableCell>
                {isAdminDs && (
                  <TableCell className={classes.tableHeaderCell}>
                    {LABELS.EngineHours}
                  </TableCell>
                )}
                <TableCell className={classes.tableHeaderCell}>
                  {LABELS.Origin}
                </TableCell>
                <TableCell className={classes.tableHeaderCell}>
                  {LABELS.Annotation}
                </TableCell>

                {isDebug && isAllLog && (
                  <TableCell className={classes.tableHeaderCell}>
                    {LABELS.details}
                  </TableCell>
                )}
                {!isViewOnly && (
                  <TableCell className={classes.tableHeaderCell} />
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(driverLogs) &&
                driverLogs.map((item, i) => {
                  if (item.isLast) {
                    return null;
                  }
                  let prevLog = driverLogs[i - 1];
                  let prevLog2 = driverLogs[i - 2];

                  if (i === 0) {
                    prevLog = item;
                    prevLog2 = item;
                  }
                  if (i === 1) {
                    prevLog2 = driverLogs[i - 1];
                  }
                  if (!item.cid) {
                    if (item.shiftReset || item.cycleResetTimestamp) {
                    } else if (!item.isStartDay) {
                      item = driverLogs[i - 1];
                    }
                  }
                  if (!prevLog.cid) {
                    prevLog = driverLogs[i - 2];
                  }
                  if (!prevLog2.cid) {
                    prevLog2 = driverLogs[i - 3];
                  }

                  return (
                    <ReduxTableRow
                      item={item}
                      prevLog={prevLog}
                      prevLog2={prevLog}
                      key={i}
                      index={i}
                      classes={classes}
                      cycleStart={cycleStart}
                      isCoDriver={isCoDriver}
                      isDebug={isDebug}
                      isAllLog={this.props.isAllLog}
                      isAdminDs={isAdminDs}
                      isAdminDsMul={isAdminDsMul}
                      timezone={timezone}
                      isFmcsaSend={isFmcsaSend}
                      formatTime={this.props.formatTime}
                      searchLogStatus={this.props.searchLogStatus}
                      setSelectedLog={this.props.setSelectedLog}
                      handleReassignDrivingModal={
                        this.handleReassignDrivingModal
                      }
                      formatRawDate={this.props.formatRawDate}
                      deleteAdminLogs={(selected) => {
                        this.setState({
                          selected,
                          isConfirmOpen: true,
                        });
                      }}
                      deleteCalcus={(selected) => {
                        this.setState({
                          selected,
                          isConfirmCalcusOpen: true,
                        });
                      }}
                      handleLogEdit={this.props.handleLogEdit}
                      adminScope={this.props.adminScope}
                      role={role}
                      isQa={this.props.isQa}
                      startOfDay={this.props.startOfDay}
                      adminKey={adminKey}
                      isUnity={isUnity}
                      handleCheckChange={this.handleCheckChange}
                      formatTimeSec={this.props.formatTimeSec}
                      isViewOnly={isViewOnly}
                      isSmartelds={this.props.isSmartelds}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

CurrentStatusDetailTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CurrentStatusDetailTable);
