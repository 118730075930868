import axios from "./axios";
import _get from "lodash/get";
import * as TYPES from "./logActionTypes";

export const setDriverLogs = (value) => ({
  type: TYPES.DRIVER_LOGS,
  value,
});

export const setSelectedLog = (value) => ({
  type: TYPES.SELECTED_LOG,
  value,
});

export const setDriverLastLogs = (value) => ({
  type: TYPES.DRIVER_LAST_LOGS,
  value,
});

export const setDriverNextLogs = (value) => ({
  type: TYPES.DRIVER_NEXT_LOGS,
  value,
});

export const onLogsBusy = (value) => ({
  type: TYPES.LOGS_BUSY,
  value,
});

export const onLogsAddBusy = (value) => ({
  type: TYPES.LOGS_ADD_BUSY,
  value,
});

export const onLogsEditBusy = (value) => ({
  type: TYPES.LOGS_EDIT_BUSY,
  value,
});

export const setDriverDiaryCount = (value) => ({
  type: TYPES.DRIVER_DAILY_DIARY_COUNT,
  value,
});

export const setDriverDiary = (value) => ({
  type: TYPES.DRIVER_DAILY_DIARY,
  value,
});

export const setDriverCalcus = (value) => ({
  type: TYPES.DRIVER_CALCUS,
  value,
});

export const onDiaryBusy = (value) => ({
  type: TYPES.LOGS_DAILY_BUSY,
  value,
});

export const onLogDeleteBusy = (value) => ({
  type: TYPES.LOGS_DELETE_BUSY,
  value,
});

export const setUnidentifiedLog = (value) => ({
  type: TYPES.LOG_UNIDENTIFIED,
  value,
});

export const setUnidentifiedLogs = (value) => ({
  type: TYPES.LOGS_UNIDENTIFIED,
  value,
});

export const setUnidentifiedLogsCount = (value) => ({
  type: TYPES.LOGS_UNIDENTIFIED_COUNT,
  value,
});

export const setMalfunctionLogs = (value) => ({
  type: TYPES.LOGS_MALFUNCTION,
  value,
});

export const setMalfunctionLogsCount = (value) => ({
  type: TYPES.LOGS_MALFUNCTION_COUNT,
  value,
});

export const setLogEdits = (value) => ({
  type: TYPES.LOG_EDITS,
  value,
});

export const setLogEditsCount = (value) => ({
  type: TYPES.LOG_EDITS_COUNT,
  value,
});

export function getAllCalcus(params = {}) {
  return (dispatch, getState) => {
    const STATUS_BASE_URL = _get(getState(), "appData.STATUS_BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");
    const driverId = params.driverId;

    return axios({
      url: `${STATUS_BASE_URL}/manager/${accountId}/drivers/${driverId}/calcus`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        dispatch(setDriverCalcus(_response));
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function getDriverLogs(params = {}) {
  return (dispatch, getState) => {
    const STATUS_BASE_URL = _get(getState(), "appData.STATUS_BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");
    const timezone = _get(getState(), "appData.timezone");
    const driverId = params.driverId;

    dispatch(onLogsBusy(true));
    dispatch(getAllCalcus(params));

    return axios({
      url: `${STATUS_BASE_URL}/manager/${accountId}/drivers/${driverId}/status/search`,
      method: "post",
      data: {
        timezone,
        showAll: params.showAll,
        from: params.from,
        to: params.to,

        endOfDay: params.endOfDay,
        startOfDay: params.startOfDay,
      },
    })
      .then((response) => {
        const _responseStatus = _get(response, "data.driverStatus", []);
        const _responseLastStatus = _get(response, "data.lastStatus", {});
        const _responseNextStatus = _get(response, "data.nextStatus", {});

        dispatch(setDriverLogs(_responseStatus));
        dispatch(setDriverLastLogs(_responseLastStatus));
        dispatch(setDriverNextLogs(_responseNextStatus));

        return {
          selectedDate: params.startOfDay,
          driverLogs: _responseStatus,
          driverLastLogs: _responseLastStatus,
          driverNextLogs: _responseNextStatus,
        };
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        dispatch(onLogsBusy(false));
        return response;
      });
  };
}

export function getDriverLogById(params = {}) {
  return async (dispatch, getState) => {
    const STATUS_BASE_URL = _get(getState(), "appData.STATUS_BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");
    const driverId = params.driverId;
    const statusId = params.statusId;

    try {
      const response = await axios({
        url: `${STATUS_BASE_URL}/manager/${accountId}/drivers/${driverId}/status/${statusId}`,
        method: "get",
        params: {},
      });
      const _response = _get(response, "data", {});
      return _response;
    } catch (error) {
      const message = _get(error, "response.data.errors[0].message");
      return message;
    }
  };
}

export function updateDriverLog(params = {}) {
  return (dispatch, getState) => {
    const STATUS_BASE_URL = _get(getState(), "appData.STATUS_BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");
    const driverId = params.driverId;
    const statusId = params.statusId;

    dispatch(onLogsEditBusy(true));

    return axios({
      url: `${STATUS_BASE_URL}/manager/${accountId}/drivers/${driverId}/status/${statusId}`,
      method: "put",
      data: {
        status: params.status,
      },
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        dispatch(onLogsEditBusy(false));
        return response;
      });
  };
}

export function deleteDriverLog(params = {}) {
  return (dispatch, getState) => {
    const STATUS_BASE_URL = _get(getState(), "appData.STATUS_BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");
    const driverId = params.driverId;
    const statusId = params.statusId;

    dispatch(onLogDeleteBusy(true));

    return axios({
      url: `${STATUS_BASE_URL}/manager/${accountId}/drivers/${driverId}/status/${statusId}`,
      method: "delete",
      data: {
        status: params.status,
      },
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        dispatch(onLogDeleteBusy(false));
        return response;
      });
  };
}

export function getDriverDailyCount(params = {}) {
  return async (dispatch, getState) => {
    const STATUS_BASE_URL = _get(getState(), "appData.STATUS_BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");
    const driverId = params.driverId;

    try {
      const response = await axios({
        url: `${STATUS_BASE_URL}/manager/${accountId}/drivers/${driverId}/daily/count`,
        method: "get",
        params,
      });
      const _response = _get(response, "data.count", 0);
      dispatch(setDriverDiaryCount(_response));
      return _response;
    } catch (error) {
      const message = _get(error, "response.data.errors[0].message");
      return message;
    }
  };
}

export function getDriverDaily(params = {}) {
  return (dispatch, getState) => {
    const STATUS_BASE_URL = _get(getState(), "appData.STATUS_BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");
    const driverId = params.driverId;

    dispatch(onDiaryBusy(true));

    return axios({
      url: `${STATUS_BASE_URL}/manager/${accountId}/drivers/${driverId}/daily`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", []);
        dispatch(setDriverDiary(_response));
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return { error: message };
      })
      .then((response) => {
        dispatch(onDiaryBusy(false));
        return response;
      });
  };
}

export function updateDriverDaily(params = {}) {
  return (dispatch, getState) => {
    const STATUS_BASE_URL = _get(getState(), "appData.STATUS_BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");
    const driverId = params.driverId;

    return axios({
      url: `${STATUS_BASE_URL}/manager/${accountId}/drivers/${driverId}/daily`,
      method: "put",
      data: params,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return { error: message };
      })
      .then((response) => {
        return response;
      });
  };
}

export function getUnidentifiedCount(params = {}) {
  return (dispatch, getState) => {
    const STATUS_BASE_URL = _get(getState(), "appData.STATUS_BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");

    return axios({
      url: `${STATUS_BASE_URL}/manager/${accountId}/drivers/unidentified/count`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data.count", 0);
        dispatch(setUnidentifiedLogsCount(_response));
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function getUnidentified(params = {}) {
  return (dispatch, getState) => {
    const STATUS_BASE_URL = _get(getState(), "appData.STATUS_BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");

    return axios({
      url: `${STATUS_BASE_URL}/manager/${accountId}/drivers/unidentified/search`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        dispatch(setUnidentifiedLogs(_response));
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function getMalfunctionCount(params = {}) {
  return (dispatch, getState) => {
    const STATUS_BASE_URL = _get(getState(), "appData.STATUS_BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");

    return axios({
      url: `${STATUS_BASE_URL}/manager/${accountId}/drivers/malfunction/count`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data.count", 0);
        dispatch(setMalfunctionLogsCount(_response));
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function getMalfunction(params = {}) {
  return (dispatch, getState) => {
    const STATUS_BASE_URL = _get(getState(), "appData.STATUS_BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");

    return axios({
      url: `${STATUS_BASE_URL}/manager/${accountId}/drivers/malfunction/search`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        dispatch(setMalfunctionLogs(_response));
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function getLogEditsCount(params = {}) {
  return (dispatch, getState) => {
    const STATUS_BASE_URL = _get(getState(), "appData.STATUS_BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");

    return axios({
      url: `${STATUS_BASE_URL}/manager/${accountId}/log-edits/count`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data.count", 0);
        dispatch(setLogEditsCount(_response));
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function getLogEdits(params = {}) {
  return (dispatch, getState) => {
    const STATUS_BASE_URL = _get(getState(), "appData.STATUS_BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");

    return axios({
      url: `${STATUS_BASE_URL}/manager/${accountId}/log-edits/search`,
      method: "post",
      data: params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        dispatch(setLogEdits(_response));
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function createEditRequest(params = {}) {
  return (dispatch, getState) => {
    const STATUS_BASE_URL = _get(getState(), "appData.STATUS_BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");
    const driverId = params.driverId;

    return axios({
      url: `${STATUS_BASE_URL}/manager/${accountId}/drivers/${driverId}/statusEdit`,
      method: "post",
      data: params,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return { error: message };
      })
      .then((response) => {
        return response;
      });
  };
}

export function createMultiEditRequest(params = {}) {
  return (dispatch, getState) => {
    const STATUS_BASE_URL = _get(getState(), "appData.STATUS_BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");
    const driverId = params.driverId;

    return axios({
      url: `${STATUS_BASE_URL}/manager/${accountId}/drivers/${driverId}/statusEdit`,
      method: "post",
      data: params,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return { error: message };
      })
      .then((response) => {
        return response;
      });
  };
}
export function getEditRequest(params = {}) {
  return (dispatch, getState) => {
    const STATUS_BASE_URL = _get(getState(), "appData.STATUS_BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");
    const driverId = params.driverId;
    const startOfDay = params.startOfDay;
    const endOfDay = params.endOfDay;

    return axios({
      url: `${STATUS_BASE_URL}/manager/${accountId}/drivers/${driverId}/statusEdit/search`,
      method: "post",
      data: { startOfDay, endOfDay },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return { error: message };
      })
      .then((response) => {
        return response;
      });
  };
}

export function deleteRequest(params = {}) {
  return (dispatch, getState) => {
    const STATUS_BASE_URL = _get(getState(), "appData.STATUS_BASE_URL");
    const accountId = _get(getState(), "authData.profile.user.accountId");
    const driverId = params.driverId;

    return axios({
      url: `${STATUS_BASE_URL}/manager/${accountId}/drivers/${driverId}/statusEdit`,
      method: "delete",
      params: {
        id: params.id,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return { error: message };
      })
      .then((response) => {
        return response;
      });
  };
}
