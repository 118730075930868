import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Formik, Form } from "formik";
import { createLead, updateLead } from "../../actions/adminLeadActions";
import { getAllUsers } from "../../actions/adminDashActions";
import {
  formatDateTimeUtc,
  formatDateTimeToUtc,
} from "../../actions/momentActions";
import { validate, defaultValues } from "./validate";
import Grid from "@mui/material/Grid";
import InputBox from "../../components/InputBox";
import InputDateBox from "../../components/InputDateBox";
import SelectBox from "../../components/SelectBox";
// import AdminAccountSearch from "../../components/AdminAccountSearch";
import Error from "../Error";
import Success from "../Success";
import { LABELS as _LABELS } from "../../language";
import { Alert, CircularProgress } from "@mui/material";
import axios from "axios";


const LABELS = _LABELS.leadForm;

const styles = (theme) => ({
  wrapper: {},
  DialogActions: {
    marginTop: 20,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export class AddLeadForm extends React.Component {
  state = {
    accounts: [],
    drivers: [],
    supUsers: [],
    loading: false,
    searching: false,
    errorMsg: "",
    successMsg: "",
    searchResult: null,
    searchCarrier: null,
    notFound:false

  };

  componentDidMount() {
    this.getSupportUsers();
  }
  onCarrierSearch = async (e) => {
    const dotNumber = e.target.value.trim(); // Get DOT number from input field
    if (!dotNumber) return; // Exit if DOT number is empty

    try {
   
      this.setState({ searching: true, notFound: false }); // Set searching state to true and reset notFound

      // Make API call using Axios
      const response = await axios.get(
        `https://api.osrit.com/cloud/safer/search?dot=${dotNumber}`
      );
      
      // Handle API response
        if (response.data.company) {
          // console.log("responseData",response.data.company
        const company = response.data.company;
        const mailingAddress = company.mailingAddress || {};
        const physicalAddress = company.physicalAddress || {};

        // Prepare search result object
        const searchResult = {
          name: company.name || "",
          contactemail: company.email || "",
          contactmob: company.phone || "",
          contactph: company.phone || "",
          email: company.email || "",
          dot: company.uSDOTNumber || "",
          mcno: company.uSDOTNumber,
          phone: company.mCMXFFNumber || "",
          line1: mailingAddress.street || "",
          line2: "",
          city: mailingAddress.city || "",
          state: mailingAddress.state || "",
          zip: mailingAddress.zip || "",
          country: "US",
          
          carrier: {
            name: company.name || "",
            line: physicalAddress.street || "",
            city: physicalAddress.city || "",
            state: physicalAddress.state || "",
            zip: physicalAddress.zip || "",
          },
        };

        this.setState({ searchResult,searching: false });
      } else {
        this.setState({ searchResult: null, searching: false ,notFound: true });
      }
    } catch (error) {
      console.error('Error fetching carrier information:', error);
      this.setState({ searching: false });
    }
  };

  setSearchData = () => {
    const { searchResult } = this.state;
    // console.log("searchResult",searchResult)
    if (searchResult) {
      this.setState({ searchCarrier: searchResult });
    }
  };


  getSupportUsers = async () => {
    const supUsers = await this.props.getAllUsers({
      skip: 0,
      limit: 500,
      searchSupport: "ON",
    });
    this.setState({ supUsers: supUsers || [] });
  };

  handleSubmit = async (values) => {
    this.setState({ errorMessage: "", successMessage: "", loading: true });

    let response;

    if (values.dueDate) {
      values.dueDate = this.props.formatDateTimeToUtc(values.dueDate);
    }
    if (values.cbDate) {
      values.cbDate = this.props.formatDateTimeToUtc(values.cbDate);
    }

    if (values._id) {
      response = await this.props.updateLead(values);
    } else {
      response = await this.props.createLead(values);
    }

    if (response && response._id) {
      this.setState({
        successMessage: LABELS.lead_is_saved_successfully,
        loading: false,
      });
      setTimeout(() => {
        this.props.handleClose();
      }, 1000);
    } else if (response && response.message) {
      this.setState({ errorMessage: response.message, loading: false });
    } else {
      if (typeof response !== String) {
        response = LABELS.something_went_wrong_please_try_after_some_time;
      }
      this.setState({ errorMessage: response, loading: false });
    }
  };

  render() {
    const { classes, constants } = this.props;
    // const accounts = this.state.accounts || [];
    const { searchResult , searchCarrier,notFound} = this.state;
    const drivers = this.state.drivers || [];
    const supUsers = this.state.supUsers || [];
    const user = this.props.user || {};
    const selected = this.props.selected || {};
    const isSmallScreen = window.innerWidth < 400;
    const { errorMessage, successMessage, loading } = this.state;
    let initialValues = {
      ...defaultValues,
      resellerId: this.props.resellerId,
      ...selected,
    };
 
    if (selected && selected.cbDate) {
      initialValues.cbDate = this.props.formatDateTimeUtc(selected.cbDate);
    }
    if (selected && selected.dueDate) {
      initialValues.dueDate = this.props.formatDateTimeUtc(selected.dueDate);
    }
  

    if (searchCarrier && searchCarrier.name) {
      // console.log("searchCarrier",searchCarrier)
      initialValues = { ...initialValues, ...searchCarrier };
      initialValues.physicalAddress = initialValues.physicalAddress || {};
    }
    
    return (
      <Dialog
        fullScreen={isSmallScreen}
        fullWidth={true}
        maxWidth={"xl"}
        className={classes.wrapper}
        open={this.props.isAdd}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography>{LABELS.leads}</Typography>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {searchResult && (
          <Alert color="primary">
            <h5 className="text-center">
              <strong>FOUND ON SAFER.GOV (FMSCSA) </strong>
            </h5>
            <Grid>
              <Grid md={8}>
                <div>
                  <strong>{searchResult.name}</strong>
                  <div>DOT- {searchResult.dot}</div>
                  {searchResult.phone && <div>Phone- {searchResult.phone}</div>}
                  {searchResult.email && <div>Email- {searchResult.email}</div>}
                  <div>
                    Address: {searchResult.line1} {searchResult.city}{" "}
                    {searchResult.state}-{searchResult.zip}
                  </div>
                </div>
              </Grid>
              <Grid md={4} className="text-center">
                <Button
                variant="contained"
                  color={"primary"}
                  size={"sm"}
                  onClick={this.setSearchData}
                  style={{ marginTop: 25 }}
                >
                  {"USE THIS CARRIER"}
                </Button>
              </Grid>
            </Grid>
          </Alert>
        )} 
          {notFound && ( // Render not found message
          <Alert severity="error" style={{ marginBottom: 10 }}>
            Data not found for this DOT number.
          </Alert>
        )}
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validate={validate}
            onSubmit={this.handleSubmit}
            enableReinitialize={true}
          >
            
            {({ values, handleSubmit, setFieldValue, errors }) => {
              return (
                <Form style={{ padding: "20px 10px" }}>
                    {this.state.searching && (
                  <Grid md={12} >
                    <Alert color="primary" className="text-center">
                      <CircularProgress color="primary"  style={{margin:"auto"}}/>
                      <div style={{textAlign:"center"}}>
                        Searching carrier information on
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`https://safer.fmcsa.dot.gov/CompanySnapshot.aspx`}
                        >
                          {" https://safer.fmcsa.dot.gov "}
                        </a>
                        .
                      </div>
                    </Alert>
                  </Grid>
                )}
                  <Grid container spacing={3}>
                
                    <SelectBox
                      md={3}
                      name="channel"
                      label={LABELS.channel}
                      items={constants.LEAD_CHANNEL}
                    />
                    <SelectBox
                      md={3}
                      name="status"
                      label={LABELS.status}
                      items={constants.LEAD_STATUS}
                    />
                    <InputBox md={3} name="dot"
                     label={LABELS.dot}
                     onBlur={this.onCarrierSearch}
                     />
                    <InputBox md={3} name="carrier.name" label={LABELS.name}
                     />
                    <InputBox
                      md={3}
                      name="carrier.line"
                      label={LABELS.adress_line}
                    />
                    <InputBox
                      md={3}
                      name="carrier.city"
                      label={LABELS.address_city}
                      
                    />
                    <InputBox
                      md={3}
                      name="carrier.state"
                      label={LABELS.address_state}
                    />
                    <InputBox
                      md={3}
                      name="carrier.zip"
                      label={LABELS.address_zip}
                    />
                    <InputBox
                      md={4}
                      name="name"
                      label={LABELS.contact_name}
                    />
                    <InputBox
                      md={4}
                      name="email"
                      label={LABELS.contact_email}
                    />
                    <InputBox
                      md={4}
                      name="contactph"
                      label={LABELS.contact_phone}
                    />
                    <InputDateBox
                      md={3}
                      name="cbDate"
                      label={LABELS.callback}
                      type="datetime-local"
                    />
                    <SelectBox
                      md={3}
                      selValue={"id"}
                      selLabel={"firstName"}
                      selLabelTwo={"lastName"}
                      selLabelThree={"status"}
                      name="assignedTo.id"
                      label={`${LABELS.assignedto}`}
                      items={supUsers}
                      onChange={(e) => {
                        const _uId = e.target.value;
                        const _sel =
                          supUsers.find((item) => item.id === _uId) || {};

                        setFieldValue(
                          "assignedTo.name",
                          `${_sel.firstName} ${_sel.lastName}`
                        );
                        setFieldValue("assignedTo.email", _sel.email);
                        setFieldValue("assignedTo.id", _uId);
                      }}
                    />
                    <InputBox
                      md={3}
                      name="assignedTo.name"
                      label={`${LABELS.assignedto}`}
                    />
                    <InputBox
                      md={3}
                      name="assignedTo.email"
                      label={`${LABELS.assignee_email}`}
                    />
                    <InputBox
                      md={12}
                      name="description"
                      label={LABELS.description}
                    />
                  </Grid>
                  {successMessage && <Success message={successMessage} />}
                  {errorMessage && <Error message={errorMessage} />}
                  <DialogActions className={classes.DialogActions}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={loading}
                    >
                      {/* {loading ? "Saving..." : "SAVE"} */}
                      {`${loading ? LABELS.saving : LABELS.save}`}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={this.props.handleClose}
                    >
                      {LABELS.close}
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: _get(state, "authData.profile.user", {}),
    resellerId: _get(state, "authData.profile.user.resellerId", ''),
    constants: _get(state, "appData.constants", {}),
    errorMessage: _get(state, "equipmentData.error", ""),
    loading: _get(state, "accountData.accountCreateBusy", false),
    accountValues: _get(state, "form.accountForm.values", {}),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createLead,
      updateLead,
      getAllUsers,
      formatDateTimeUtc,
      formatDateTimeToUtc,
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AddLeadForm))
);
